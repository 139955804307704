import { createGlobalStyle } from 'styled-components'
// eslint-disable-next-line import/no-unresolved
import { PancakeTheme } from '@pancakeswap/uikit/dist/theme'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Kanit', sans-serif;
  }
  .coolgradient{
    background: linear-gradient(95.79deg, #0167FF 6.93%, #FA06FF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  /* text-fill-color: transparent; */
  
  }


  .coolgradient2{
    background: linear-gradient(95.79deg, #0167FF 4.35%, #FA06FF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  /* text-fill-color: transparent; */
  
  }

  .customul {
    list-style-image: url('/images/checked.svg');
  }

  body {
    // background: url('/images/bg.png');
    // background-size: cover;
    // background: rgb(221, 222, 224);
    background: url("/images/bg.png");
    background-size: cover;

    img {
      height: auto;
      max-width: 100%;
    }
  }
`

export default GlobalStyle
